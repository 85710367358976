import React from "react";
import "./ovalNeumorphicBtn.scss";

const OvalNeumorphicBtn = ({
  children,
  onClick,
  style = {},
  icon,
  fontSize,
  fontFamily,
  fontWeight,
  color,
  padding,
  borderRadius,
  boxShadow,
  backgroundColor,
  border,
  outline,
  text = "",
  textDecoration,
  className = "",
}) => {
  const buttonStyle = {
    fontSize,
    fontFamily,
    fontWeight,
    color,
    padding,
    borderRadius,
    boxShadow,
    backgroundColor,
    border,
    outline,
    textDecoration,
    cursor: "pointer",
    transition: "all 0.3s ease",
    ...style,
  };

  return (
    <button
      className={`oval-neumorphic-btn ${className}`}
      onClick={onClick}
      style={buttonStyle}
    >
      {icon && <span className="icon">{icon}</span>}
      {text}
      {children}
    </button>
  );
};

export default OvalNeumorphicBtn;
