import React, { useState, useEffect } from "react";
import { emoteOptions } from "../../constants/emoteOptions";
import "./emoji.scss";

const EmotionEmoji = ({
  onSelectEmoji, // This is now an optional prop by applying the if condition
  currentEmotionId,
  showAllEmojis,
}) => {
  const [showAll, setShowAll] = useState(showAllEmojis);

  // Use onSelectEmoji prop when an emoji is clicked
  const chooseEmoji = (emotionconId) => {
    console.log(`Emoji clicked: ${emotionconId}`);
    // Check if onSelectEmoji was provided before calling it
    if (onSelectEmoji) {
      onSelectEmoji(emotionconId);
      setShowAll(false); // Hide the emoji selection if necessary
    }
  };

  useEffect(() => {
    setShowAll(showAllEmojis);
  }, [showAllEmojis]);

  const renderExpression = (emojiChoice) => {
    switch (emojiChoice) {
      case "wow":
        return (
          <div className="emotionCons-wow-face" onClick={() => chooseEmoji(7)}>
            <div className="emotionCons-wow-expression">
              <div className="emotionCons-wow-eyes emotionCons-wow-eye-left"></div>
              <div className="emotionCons-wow-eyes emotionCons-wow-eye-right "></div>
              <div className="emotionCons-wow-eyebrows emotionCons-wow-eyebrow-right"></div>
              <div className="emotionCons-wow-eyebrows emotionCons-wow-eyebrow-left"></div>
              <div className="emotionCons-wow-mouth"></div>
            </div>
          </div>
        );
      case "serious":
        return (
          <div
            className="emotionCons-serious-face"
            onClick={() => chooseEmoji(6)}
          >
            <div className="emotionCons-serious-expression">
              <div className="emotionCons-serious-eyebrows"></div>
              <div className="emotionCons-eye emotionCons-eye-left"></div>
              <div className="emotionCons-eye emotionCons-eye-right"></div>
              <div className="emotionCons-serious-mouth"></div>
            </div>
          </div>
        );
      case "teethy-laugh":
        return (
          <div
            className="emotionCons-teethy-laugh-face"
            onClick={() => chooseEmoji(5)}
          >
            <div className="emotionCons-teethy-laugh-expression">
              <div className="emotionCons-teethy-laugh-eyebrows"></div>
              <div className="emotionCons-teethy-laugh-eye emotionCons-teethy-laugh-eye-left"></div>
              <div className="emotionCons-teethy-laugh-eye emotionCons-teethy-laugh-eye-right"></div>
              <div className="emotionCons-teethy-laugh-mouth"></div>
            </div>
          </div>
        );
      case "tongue-laugh":
        return (
          <div
            className="emotionCons-tongue-laugh-face"
            onClick={() => chooseEmoji(4)}
          >
            <div className="emotionCons-tongue-laugh-expression">
              <div className="emotionCons-tongue-laugh-eyebrows"></div>
              <div className="emotionCons-tongue-laugh-eye emotionCons-tongue-laugh-eye-left"></div>
              <div className="emotionCons-tongue-laugh-eye emotionCons-tongue-laugh-eye-right"></div>
              <div className="emotionCons-tongue-laugh-mouth">
                <div className="emotionCons-tongue"></div>
              </div>
            </div>
          </div>
        );
      case "zipper-face":
        return (
          <div
            className="emotionCons-zipper-face"
            onClick={() => chooseEmoji(3)}
          >
            <div className="emotionCons-zipper-face-eye emotionCons-zipper-face-eye-left"></div>
            <div className="emotionCons-zipper-face-eye emotionCons-zipper-face-eye-right"></div>
            <div className="zipper">
              <div
                className="zipper-track"
                style={{ "--delay": "0s", zIndex: "1" }}
              ></div>
              <div
                className="zipper-track"
                style={{ "--delay": "0.2s", zIndex: "1" }}
              ></div>
              <div
                className="zipper-track"
                style={{ "--delay": "0.4s", zIndex: "1" }}
              ></div>
              <div
                className="zipper-track"
                style={{ "--delay": "0.6s", zIndex: "1" }}
              ></div>
              <div
                className="zipper-track"
                style={{ "--delay": "0.8s", zIndex: "1" }}
              ></div>
              <div className="zipper-line"></div>
              <div className="zipper-pull"></div>
              <div className="zipper-pull-hook"></div>
              <div className="zipper-pull-attacher"></div>
            </div>
          </div>
        );
      case "heart-eyes-face":
        return (
          <div
            className="emotionCons-heart-eyes-face"
            onClick={() => chooseEmoji(2)}
          >
            <div className="emotionCons-heart-eyes-expression">
              <div className="emotionCons-heart-eye emotionCons-heart-eye-left">
                ❤
              </div>
              <div className="emotionCons-heart-eye emotionCons-heart-eye-right">
                ❤
              </div>
              <div className="emotionCons-heart-eyes-mouth"></div>
            </div>
          </div>
        );
      case "wink-heart-kiss":
        return (
          <div
            className="emotionCons-wink-heart-kiss-face"
            onClick={() => chooseEmoji(1)}
          >
            <div className="emotionCons-wink-heart-kiss-expression">
              <div className="emotionCons-wink-eye emotionCons-wink-eye-left" />
              <div className="emotionCons-wink-eye emotionCons-wink-eye-right"></div>
              {/* leave the double dash for nomen clature conventions reason */}
              <div className="emotionCons--heart-mouth-kiss" />
              <div className="floating-heart">❤️</div>
            </div>
          </div>
        );
      case "yay":
        return (
          <div className="emotionCons-yay-face" onClick={() => chooseEmoji(0)}>
            <div className="emotionCons-yay-expression">
              <div className="emotionCons-yay-eye emotionCons-yay-eye-left"></div>
              <div className="emotionCons-yay-eye emotionCons-yay-eye-right"></div>
              <div className="emotionCons-yay-mouth"></div>
            </div>
          </div>
        );
      default:
        return renderExpression("yay"); // Return the default emoji if no matching emoji is found
    }
  };

  const renderDefaultOrSelectedEmoji = () => {
    const defaultEmotionId = currentEmotionId ?? 0; // Use 0 as the default if no id is present or if it's null
  
    // Check if the selected emoji exists, otherwise fallback to the default
    const selectedEmoji = emoteOptions.find(
      (option) => option.id === defaultEmotionId
    ) || emoteOptions[0]; // Default to the first emoji ('yay') if no match is found
  
    return renderExpression(selectedEmoji.emojiChoice);
  };
  
  const renderAllEmojis = () => {
    return (
      <div className="emoji-container">
        {emoteOptions.map((option) => (
          <div
            key={option.id}
            className="emoji-item"
            onClick={() => chooseEmoji(option.id)}
          >
            {renderExpression(option.emojiChoice)}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="emotionCons-selection-container thumbnail-content">
      {showAll ? renderAllEmojis() : renderDefaultOrSelectedEmoji()}
    </div>
  );
};

export default EmotionEmoji;
