/* eslint-disable react/button-has-type */
import React from "react";
import "./insetBlendTVButton.scss";
// import CustomSpinner from './customSpinner';
const InsetBlendTVButton = ({
  image = null,
  btnText,
  className = "",
  onClick,
  textClassName = "",
  textStyles = {}, // Default empty object if not provided
  style = {},
}) => {
  return (
    <button
      className={`blendTV-btn position-relative ${className}`}
      onClick={(e) => {
        if (onClick) onClick(e);
      }}
      style={style}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {image}
      </div>
      <span className={`${textClassName}`} style={textStyles}>{btnText}</span>
    </button>
  );
};

export default InsetBlendTVButton;
