import { emojiUnicodeChoice} from "../constants/unicodeEmojiOptions";

export const getEmojiByName = (name) => {
  for (const [emoji, { name: emojiName }] of Object.entries(emojiUnicodeChoice)) {
    if (emojiName === name) {
      return emoji;
    }
  }
  return null; // Return null if no matching emoji is found
};
