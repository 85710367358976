
export const themeContainerStyleColors = {
  glass: "var(--color-glass)",
  blue: "var(--color-blue)",
  red: "var(--color-red)",
  gum: "var(--color-gum)",
  white: "var(--color-white)",
  ivory: "var(--color-ivory)",
  offWhite: "var(--color-offWhite )",
  pink: "var(--color-pink)",
  brown: "var(--color-brown)",
  maroon: "var(--color-maroon)",
  redish: "var(--color-redish)",
  bloodred: "var(--color-bloodred)",
  purple: "var(--color-purple)",
  orange: "var(--color-orange)",
  yellow: "var(--color-yellow)",
  yellowgold: "var(--color-yellowgold)",
  purple: "var(--color-purple)",
  military: "var(--color-military)",
  corveteRed: "var(--color-corveteRed)",
  transparent: "var(--color-trasparent)",
  beige: "var( --color-beige)",
  teal: "var(--color-teal)",
  burgundy: "var(--color-burgundy)",
  steal: 'theme-steal', // Gradient class
  jean: 'theme-jean',   // Gradient class
  greyish: 'theme-greyish', // Gradient class
  gold: "var(--color-gold)",
};


