import React, { useEffect, useRef, } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { SmallPlainInsetBtn, MemberSlider } from "../buttons";
import { XIcon } from "../icons";
import { getEmojiByName } from "../../utils/emojiUtils";
import { useAuth } from "../context/authContext";



const NotificationItem = ({ notification, markAsRead }) => {
  const navigate = useNavigate();
  const location = useLocation(); // Use location to detect route changes
  const linkRef = useRef(null);
  const { currentUser } = useAuth(); // Get the current user

  const handleMarkAsRead = async () => {
    await markAsRead(notification.id);
  };

  const handleProfileThumbnailSlideEnd = (userId) => {
    if (userId) {
      console.log("Navigating to user profile:", userId);
      navigate(`/user-profile/${userId}`);
    } else {
      console.log("User ID is undefined, not navigating.");
    }
  };

  const scrollWithOffset = (el, additionalOffset = 0) => {
    const offset = 100; // Adjust this value to account for any fixed headers
    const elementPosition = el.offsetTop;
    const offsetPosition = elementPosition - offset - additionalOffset;
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    if (notification.link && linkRef.current) {
      scrollWithOffset(linkRef.current);
    }
  }, [notification.link]);

  useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll to top on route change

  }, [location.pathname]); // perform any action based on location

  const emojiUnicodeChoice = notification.emojiChoiceName
    ? getEmojiByName(notification.emojiChoiceName)
    : null;
    const handleLinkClick = (e) => {
      e.preventDefault();
      // Check if the notification is about a new subscriber
      if (notification.activity === "subscribed") {
        navigate(`/user/${currentUser}/subscribers`);
      } else {
        navigate(notification.skitUrlLink); // navigate to path from main, the url though is created and sent here from the backend.
      }
    };
  
  return (
    <div className="notificationItem">
      <div className="notificatWrapper" onClick={handleLinkClick}>
        <MemberSlider
          user={notification.source}
          slideBtnText="Slide to View Profile"
          onHandleSlideEnd={() => {
            console.log("User ID being passed:", notification.source.id);
            handleProfileThumbnailSlideEnd(notification.source.id);
          }}
        />
        <div
          className="user--notification-details"
          style={{ marginTop: "10px" , cursor: "pointer"}}
        >
          <a
            href={notification.skitUrlLink}
            // These attributes are used to open links in new tabs, which is not desired here.
            // target="_blank"
            // rel="noopener noreferrer"
            className="notification-link"
          >
            <span className="source-username">
              {notification.source.username}
            </span>
            &nbsp;
            {notification.notificationMessage}
            {emojiUnicodeChoice && ` ${emojiUnicodeChoice}`}&nbsp;
            {notification.related && (
              <span className="skit-title">
                Skit title: {notification.related.title}
              </span>
            )}
          </a>
        </div>
      </div>

      <SmallPlainInsetBtn
        image={
          <XIcon height="20px" width="20px" style={{ marginRight: "3px" }} />
        }
        text="Mark as Read"
        width="188px"
        style={{
          fontSize: "1rem",
        }}
        onClick={handleMarkAsRead}
      />
    </div>
  );
};
export default NotificationItem;
