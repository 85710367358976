/* eslint-disable react/button-has-type */
import React from "react";
import "./insetButton.scss";
// import CustomSpinner from './customSpinner';

const InsetButton = ({
  image = null,
  btnText,
  className = "",
  onClick = () => {},
  textClassName = "", 
  textStyles = {}, // Default empty object if not provided
  style = {}, 
  height,
}) => {
  return (
    <button
      className={`globalized-btn position-relative ${className}`}
      onClick={onClick}
      style={{ ...style, height }} // Merge the style object with the height prop
    >
      {image}
      <span className={`${textClassName}`} style={textStyles}>{btnText}</span>
    </button>
  );
};

export default InsetButton;
