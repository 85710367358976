import React, { useState } from "react";

// Component accepts a name, onClick function, Icon component, title, and additional styles for the icon
const RoundFlatBtns = ({
  name,
  onClick,
  Icon, // This should be a component, not an instance
  iconProps = {}, // New prop for Icon's props
  title,
  className = "",
  btnRndClassName = "",
  extraStyles = {},
  width,
  height,
  titleStyle,
  border = "0.2px solid #c79f27",
  borderRadius = "50%",
}) => {
  const [pressed, setPressed] = useState(false);

  const handleClick = () => {
    setPressed(true);
    onClick(); // Fire the click action
    setTimeout(() => setPressed(false), 500); // Reset the pressed state after a delay
  };

  const buttonStyles = {
    cursor: "pointer",
    display: "flex", // Enable flexbox
    justifyContent: "center", // Center children horizontally
    alignItems: "center", // Center children vertically
    flexDirection: "column", // Stack children vertically
    backgroundColor: "#1c1c1c",
    border,
    borderRadius,
    boxShadow: pressed
      ? "inset 0 0 10px 0px rgba(0,0,0,0.5)"
      : "0 0 10px 0px rgba(0, 0, 0, 0.5)",
    transition: "background-color 1s cubic-bezier(0.075, 0.82, 0.165, 1)", // Correct syntax for default parameter
    ...(height && { height }),
    ...(width && { width }),
    ...extraStyles,
  };

  // Apply themeClassName if it's a gradient theme
  const combinedClassName = `${className} ${btnRndClassName} ${
    pressed ? "pressed-btn-effect" : ""
  }`.trim();

  return (
    <div
      name={name}
      className={combinedClassName}
      onMouseDown={() => setPressed(true)}
      onMouseUp={() => setPressed(false)}
      onMouseLeave={() => setPressed(false)}
      onClick={handleClick}
      style={buttonStyles}
    >
      {Icon && <Icon {...iconProps} />}
      <h4 style={titleStyle}>{title}</h4>
    </div>
  );
};

export default RoundFlatBtns;
