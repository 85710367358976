import React from "react";
import "./plainBtn.scss";

const PlainBtn = ({
  className = "white-gold-plain-btn",
  onClick,
  disabled = false,
  isSubmit = false,
  image = "",
  plainBtnText = "",
  plainBtnTextFontSize,
  plainBtnWidth,
  plainBtnHeight,
  plainBtnFontFamily,
  plainBtnBoxShadow,
  plainBtnFontWeight,
  plainBtnFontColor,
  plainBtnBorderRadius,
  marginLeft = "0px",
  marginRight = "0px",
  marginTop = "0px",
  marginBottom = "0px",
  animationActive = false,
  imageOnTop = false, // New prop for image position
  reverseOrder = true, // New prop for reversing order
  extraStyles = {},
  textLocalWhiteBtnStyle = {},
}) => (
  <button
    className={`white-gold-plain-btn ${className}`}
    onClick={onClick}
    disabled={disabled}
    type={isSubmit ? "submit" : "button"}
    style={{
      width: plainBtnWidth,
      height: plainBtnHeight,
      borderRadius: plainBtnBorderRadius,
      boxShadow: `${plainBtnBoxShadow} !important`,
      marginLeft: marginLeft,
      marginRight: marginRight,
      marginTop: marginTop,
      marginBottom: marginBottom,
      animation: animationActive ? "active 0.9s alternate infinite" : "none",
      ...extraStyles,
    }}
  >
    <div
      style={{
        display: "flex",
        flexDirection: imageOnTop
          ? "column"
          : reverseOrder
          ? "row-reverse"
          : "row",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {imageOnTop && image}
      <span
        style={{
          fontSize: plainBtnTextFontSize,
          fontFamily: plainBtnFontFamily,
          fontWeight: plainBtnFontWeight,
          color: plainBtnFontColor,
          marginLeft: reverseOrder ? "10px" : "0",
          marginRight: reverseOrder ? "0" : "10px",
          ...textLocalWhiteBtnStyle, // New prop for custom styles
        }}
      >
        {plainBtnText}
      </span>
      {!imageOnTop && image}
    </div>
  </button>
);

export default PlainBtn;
