// include disabled prop
const SmallPlainInsetBtnStyles = {
  color: "#b03a2e",
  textShadow: "0 0 black",
  fontSize: "0.75rem",
  lineHeight: "14px",
  fontWeight: "700",
  fontFamily: '"Roboto", sans-serif',
  textDecoration: "none",
  border: "1.5px solid var(--color-gold)",
  borderRadius: "30px",
  whiteSpace: "nowrap",
  backgroundImage: "linear-gradient(0deg, #d8d9db 0, #fff 80%, #fdfdfd)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  height: "30px",
};

const SmallPlainInsetBtnEventListener = ({
  onClick,
  style = {},
  className = "",
  disabled,
  image,
  text,
  icon,
  width = "110px", // Default width if not provided
}) => {
  // Determine the disabled state based on the time prop
  // Conditional style based on disabled state
  const dynamicStyle = disabled
    ? { color: "black", cursor: "not-allowed" } // Corrected style for when the button is disabled
    : { color: "#b03a2e" }; // Style for when the button is enabled
  return (
    <button
      style={{
        width,
        ...SmallPlainInsetBtnStyles,
        ...style,
        ...dynamicStyle,
      }}
      className={className}
      onClick={(e) => {
        if (onClick) onClick(e);
      }}
      disabled={disabled} // Use the disabled prop directly
    >
      {image && <span>{image}</span>}
      {/* Render image here */}
      {text}
      {icon}
    </button>
  );
};
export default SmallPlainInsetBtnEventListener;
