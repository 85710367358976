export const emojiUnicodeChoice = {
  "🎉": {
    id: "partyPopper",
    name: "partyPopper",
  },
  "😍": {
    id: "HeartEyes-01",
    name: "Heart Eyes",
  },
  "🙂": {
    id: "SlightlySmiling-02",
    name: "Slightly Smiling",
  },
  "😡": {
    id: "Angry-03",
    name: "Angry",
  },
  "😭": {
    id: "Crying-04",
    name: "Crying",
  },
  "🤬": {
    id: "Cursing-05",
    name: "Cursing",
  },
  "🤨": {
    id: "RaisedEyebrow-06",
    name: "Raised Eyebrow",
  },
  "🥰": {
    id: "SmilingFaceWithHearts-07",
    name: "Smiling Face With Hearts",
  },
  "😇": {
    id: "Angel-08",
    name: "Angel",
  },
  "😎": {
    id: "Cool-09",
    name: "Cool",
  },
  "🙃": {
    id: "UpsideDown-10",
    name: "Upside Down",
  },
  "😶": {
    id: "FaceWithoutMouth-11",
    name: "Face Without Mouth",
  },
  "😄": {
    id: "GrinningFaceWithSmilingEyes-12",
    name: "Grinning Face With Smiling Eyes",
  },
  "😬": {
    id: "Grimacing-13",
    name: "Grimacing",
  },
  "😮": {
    id: "OpenMouth-14",
    name: "Open Mouth",
  },
  "😂": {
    id: "TearsOfJoy-15",
    name: "Tears Of Joy",
  },
  "😘": {
    id: "FaceBlowingaKiss-77",
    name: "Face Blowing a Kiss",
  },
  "😗": {
    id: "KissingFace-78",
    name: "Kissing Face",
  },
  "🤐": {
    id: "ZipperMouthFace-79",
    name: "Zipper Mouth Face",
  },
  "😵": {
    id: "DizzyFace-80",
    name: "Dizzy Face",
  },
  "😷": {
    id: "FaceWithMedicalMask-81",
    name: "Face With Medical Mask",
  },
  "🤫": {
    id: "ShushingFace-82",
    name: "Shushing Face",
  },
  "😖": {
    id: "ConfoundedFace-83",
    name: "Confounded Face",
  },
  "🥸": {
    id: "DisguisedFace-84",
    name: "Disguised Face",
  },
  "🤗": {
    id: "HuggingFace-85",
    name: "Hugging Face",
  },
  "😦": {
    id: "FrowningFaceWithOpenMouth-86",
    name: "Frowning Face With Open Mouth",
  },
  "😉": {
    id: "WinkingFace-87",
    name: "Winking Face",
  },
  "😰": {
    id: "AnxiousFaceWithSweat-88",
    name: "Anxious Face With Sweat",
  },
  "😫": {
    id: "TiredFace-89",
    name: "Tired Face",
  },
  "🕶": {
    id: "Sunglasses-91",
    name: "Sunglasses",
  },
  "🤣": {
    id: "RollingOnTheFloorLaughing-92",
    name: "Rolling On The Floor Laughing",
  },
  "😁": {
    id: "BeamingFaceWithSmilingEyes-35",
    name: "Beaming Face With Smiling Eyes",
  },
  "🥹": {
    id: "Pleading-36",
    name: "Pleading",
  },
  "🤑": {
    id: "MoneyMouthFace-37",
    name: "Money Mouth Face",
  },
  "😳": {
    id: "Flushed-38",
    name: "Flushed",
  },
  "🥷": {
    id: "Ninja-39",
    name: "Ninja",
  },
  "🤢": {
    id: "Nauseated-40",
    name: "Nauseated",
  },
  "🎧": {
    id: "Headphone-41",
    name: "Headphone",
  },
  "☠": {
    id: "SkullAndCrossbones-42",
    name: "Skull And Crossbones",
  },
  "😴": {
    id: "Sleeping-43",
    name: "Sleeping",
  },
  "🤩": {
    id: "StarStruck-44",
    name: "Star Struck",
  },
  "👑": {
    id: "Crown-45",
    name: "Crown",
  },
  "😱": {
    id: "ScreamingInFear-46",
    name: "Screaming In Fear",
  },
  "🤔": {
    id: "Thinking-47",
    name: "Thinking",
  },
  "🤒": {
    id: "FaceWithThermometer-48",
    name: "Face With Thermometer",
  },
  "🥱": {
    id: "Yawning-49",
    name: "Yawning",
  },
  "👻": {
    id: "Ghost-50",
    name: "Ghost",
  },
  "👨🏻‍🦱": {
    id: "ManCurlyHair-51",
    name: "Man Curly Hair",
  },
  "👨": {
    id: "Man-52",
    name: "Man",
  },
  "🌝": {
    id: "FullMoonFace-53",
    name: "Full Moon Face",
  },
  "😽": {
    id: "KissingCat-54",
    name: "Kissing Cat",
  },
  "🙋‍♂️": {
    id: "ManRaisingHand-55",
    name: "Man Raising Hand",
  },
  "💁‍♂️": {
    id: "ManTippingHand-56",
    name: "Man Tipping Hand",
  },
  "🤷‍♂️": {
    id: "ManShrugging-57",
    name: "Man Shrugging",
  },
  "🤦‍♂️": {
    id: "ManFacepalming-58",
    name: "Man Facepalming",
  },
  "😲": {
    id: "Astonished-60",
    name: "Astonished",
  },
  "😋": {
    id: "FaceSavoringFood-61",
    name: "Face Savoring Food",
  },
  "🤯": {
    id: "MindBlown-62",
    name: "Mind Blown",
  },
  "🥳": {
    id: "Partying-63",
    name: "Partying",
  },
  "😬": {
    id: "GrimacingFace-95",
    name: "Grimacing Face",
  },
  "😜": {
    id: "WinkingTongueOut-65",
    name: "Winking Tongue Out",
  },
  "😛": {
    id: "TongueOut-67",
    name: "Tongue Out",
  },
  "😝": {
    id: "TongueOutEyesClosed-66",
    name: "Tongue Out Eyes Closed",
  },
  "🤪": {
    id: "Zany-68",
    name: "Zany",
  },
  "🤥": {
    id: "LyingFace-69",
    name: "Lying Face",
  },
  "🙌": {
    id: "RaisingHands-70",
    name: "Raising Hands",
  },
  "🤷": {
    id: "PersonShrugging-71",
    name: "Person Shrugging",
  },
  "🤦": {
    id: "PersonFacepalming-72",
    name: "Person Facepalming",
  },
  "🥸": {
    id: "DisguisedFace-73",
    name: "Disguised Face",
  },
  "🤷‍♀️": {
    id: "WomanShrugging-74",
    name: "Woman Shrugging",
  },
  "🤦‍♀️": {
    id: "WomanFacepalming-75",
    name: "Woman Facepalming",
  },
  "🙋‍♀️": {
    id: "WomanRaisingHand-76",
    name: "Woman Raising Hand",
  },
  "💁‍♀️": {
    id: "WomanTippingHand-77",
    name: "Woman Tipping Hand",
  },
  "😸": {
    id: "GrinningCat-78",
    name: "Grinning Cat",
  },
  "😏": {
    id: "Smirking-79",
    name: "Smirking",
  },
  "😌": {
    id: "Relieved-80",
    name: "Relieved",
  },
  "😔": {
    id: "Sad-01",
    name: "Sad",
  },
  "😞": {
    id: "Sad-02",
    name: "Sad",
  },
  "😥": {
    id: "Sad-03",
    name: "Sad",
  },
  "😢": {
    id: "Sad-04",
    name: "Sad",
  },
  "😭": {
    id: "Sad-05",
    name: "Sad",
  },
  "😓": {
    id: "Sad-06",
    name: "Sad",
  },
  "😫": {
    id: "Sad-07",
    name: "Sad",
  },
  "😩": {
    id: "Sad-08",
    name: "Sad",
  },
  "🥺": {
    id: "Sad-09",
    name: "Sad",
  },
  "😪": {
    id: "Sad-10",
    name: "Sad",
  },
  "☹️": {
    id: "Sad-11",
    name: "Sad",
  },
  "😖": {
    id: "Sad-12",
    name: "Sad",
  },
  "😟": {
    id: "Sad-13",
    name: "Sad",
  },
  "🙁": {
    id: "Sad-14",
    name: "Sad",
  },
  "😠": {
    id: "Angry-01",
    name: "Angry",
  },
  "😡": {
    id: "Angry-02",
    name: "Angry",
  },
  "🤬": {
    id: "Angry-03",
    name: "Angry",
  },
  "😤": {
    id: "Angry-04",
    name: "Angry",
  },
  "😾": {
    id: "Angry-05",
    name: "Angry",
  },
  "😿": {
    id: "Angry-06",
    name: "Angry",
  },
  "🙎‍♂️": {
    id: "Angry-07",
    name: "Angry",
  },
  "🙎‍♀️": {
    id: "Angry-08",
    name: "Angry",
  },
  "😕": {
    id: "Other-01",
    name: "Other",
  },
  "🥴": {
    id: "Other-02",
    name: "Other",
  },
  "😰": {
    id: "Other-03",
    name: "Other",
  },
  "😨": {
    id: "Other-04",
    name: "Other",
  },
  "😧": {
    id: "Other-05",
    name: "Other",
  },
  "🥵": {
    id: "Other-06",
    name: "Other",
  },
  "🥶": {
    id: "Other-07",
    name: "Other",
  },
  "😅": {
    id: "GrinningFaceWithSweat-97",
    name: "Grinning Face With Sweat",
  },
  "🤦🏻‍♀️": {
    id: "WomanFacepalmingLightSkinTone-78",
    name: "Woman Facepalming Light Skin Tone",
  },
  "🤦🏼‍♀️": {
    id: "WomanFacepalmingMediumLightSkinTone-79",
    name: "Woman Facepalming Medium Light Skin Tone",
  },
  "🤦🏽‍♀️": {
    id: "WomanFacepalmingMediumSkinTone-80",
    name: "Woman Facepalming Medium Skin Tone",
  },
  "🤦🏾‍♀️": {
    id: "WomanFacepalmingMediumDarkSkinTone-81",
    name: "Woman Facepalming Medium Dark Skin Tone",
  },
  "🤦🏿‍♀️": {
    id: "WomanFacepalmingDarkSkinTone-82",
    name: "Woman Facepalming Dark Skin Tone",
  },
  "🤦🏻‍♂️": {
    id: "ManFacepalmingLightSkinTone-83",
    name: "Man Facepalming Light Skin Tone",
  },
  "🤦🏼‍♂️": {
    id: "ManFacepalmingMediumLightSkinTone-84",
    name: "Man Facepalming Medium Light Skin Tone",
  },
  "🤦🏽‍♂️": {
    id: "ManFacepalmingMediumSkinTone-85",
    name: "Man Facepalming Medium Skin Tone",
  },
  "🤦🏾‍♂️": {
    id: "ManFacepalmingMediumDarkSkinTone-86",
    name: "Man Facepalming Medium Dark Skin Tone",
  },
  "🤦🏿‍♂️": {
    id: "ManFacepalmingDarkSkinTone-87",
    name: "Man Facepalming Dark Skin Tone",
  },
  "🙋🏻‍♀️": {
    id: "WomanRaisingHandLightSkinTone-88",
    name: "Woman Raising Hand Light Skin Tone",
  },
  "🙋🏼‍♀️": {
    id: "WomanRaisingHandMediumLightSkinTone-89",
    name: "Woman Raising Hand Medium Light Skin Tone",
  },
  "🙋🏽‍♀️": {
    id: "WomanRaisingHandMediumSkinTone-90",
    name: "Woman Raising Hand Medium Skin Tone",
  },
  "🙋🏾‍♀️": {
    id: "WomanRaisingHandMediumDarkSkinTone-91",
    name: "Woman Raising Hand Medium Dark Skin Tone",
  },
  "🙋🏿‍♀️": {
    id: "WomanRaisingHandDarkSkinTone-92",
    name: "Woman Raising Hand Dark Skin Tone",
  },
  "🙋🏻‍♂️": {
    id: "ManRaisingHandLightSkinTone-93",
    name: "Man Raising Hand Light Skin Tone",
  },
  "🙋🏼‍♂️": {
    id: "ManRaisingHandMediumLightSkinTone-94",
    name: "Man Raising Hand Medium Light Skin Tone",
  },
  "🙋🏽‍♂️": {
    id: "ManRaisingHandMediumSkinTone-95",
    name: "Man Raising Hand Medium Skin Tone",
  },
  "🙋🏾‍♂️": {
    id: "ManRaisingHandMediumDarkSkinTone-96",
    name: "Man Raising Hand Medium Dark Skin Tone",
  },
  "🙋🏿‍♂️": {
    id: "ManRaisingHandDarkSkinTone-97",
    name: "Man Raising Hand Dark Skin Tone",
  },
  "💁🏻‍♀️": {
    id: "WomanTippingHandLightSkinTone-98",
    name: "Woman Tipping Hand Light Skin Tone",
  },
  "💁🏼‍♀️": {
    id: "WomanTippingHandMediumLightSkinTone-99",
    name: "Woman Tipping Hand Medium Light Skin Tone",
  },
  "💁🏽‍♀️": {
    id: "WomanTippingHandMediumSkinTone-100",
    name: "Woman Tipping Hand Medium Skin Tone",
  },
  "💁🏾‍♀️": {
    id: "Woman Tipping Hand Medium Dark Skin Tone-101",
    name: "Woman Tipping Hand Medium Dark Skin Tone",
  },
  "💁🏿‍♀️": {
    id: "Woman Tipping Hand Dark Skin Tone-102",
    name: "Woman Tipping Hand Dark Skin Tone",
  },
  "💁🏻‍♂️": {
    id: "Man Tipping Hand Light Skin Tone-103",
    name: "Man Tipping Hand Light Skin Tone",
  },
  "💁🏼‍♂️": {
    id: "Man Tipping Hand Medium Light Skin Tone-104",
    name: "Man Tipping Hand Medium Light Skin Tone",
  },
  "💁🏽‍♂️": {
    id: "Man Tipping Hand Medium Skin Tone-105",
    name: "Man Tipping Hand Medium Skin Tone",
  },
  "💁🏾‍♂️": {
    id: "Man Tipping Hand Medium Dark Skin Tone-106",
    name: "Man Tipping Hand Medium Dark Skin Tone",
  },
  "💁🏿‍♂️": {
    id: "Man Tipping Hand Dark Skin Tone-107",
    name: "Man Tipping Hand Dark Skin Tone",
  },
  "❤️": {
    id: "Red Heart",
    name: "Red Heart",
  },
  "💘": {
    id: "Heart with Arrow",
    name: "Heart With Arrow",
  },
  "💝": {
    id: "Heart with Ribbon",
    name: "Heart With Ribbon",
  },
  "💖": {
    id: "Sparkling Heart",
    name: "Sparkling Heart",
  },
  "💗": {
    id: "Growing Heart",
    name: "Growing Heart",
  },
  "💓": {
    id: "Beating Heart",
    name: "Beating Heart",
  },
  "💞": {
    id: "Revolving Hearts",
    name: "Revolving Hearts",
  },
  "💕": {
    id: "Two Hearts",
    name: "Two Hearts",
  },
  "❤️": {
    id: "Red Heart",
    name: "Red Heart",
  },
  "❤️‍🔥": {
    id: "Heart on Fire",
    name: "Heart On Fire",
  },
  "❤️‍🩹": {
    id: "Mending Heart",
    name: "Mending Heart",
  },
  "🧡": {
    id: "Orange Heart",
    name: "Orange Heart",
  },
  "💛": {
    id: "Yellow Heart",
    name: "Yellow Heart",
  },
  "💚": {
    id: "Green Heart",
    name: "Green Heart",
  },
  "💙": {
    id: "Blue Heart",
    name: "Blue Heart",
  },
  "💜": {
    id: "Purple Heart",
    name: "Purple Heart",
  },
  "🤎": {
    id: "Brown Heart",
    name: "Brown Heart",
  },
  "🖤": {
    id: "Black Heart",
    name: "Black Heart",
  },
  "🤍": {
    id: "White Heart",
    name: "White Heart",
  },
  "💔": {
    id: "Broken Heart",
    name: "Broken Heart",
  },
  "🫶": {
    id: "Human Hand Heart",
    name: "Human Hand Hear",
  },
  "👏": {
    id: "Clapped",
    name: "Clapped",
  },
  "👀": {
    id: "Eye Speech",
    name: "Eye Speech",
  },
  "💬": {
    id: "Speech Balloon",
    name: "Speech Balloon",
  },
  "✨": {
    id: "Sparkle",
    name: "Sparkle",
  },
};
