import Axios, { REQUEST_METHOD, config } from "../axios"; // Adjust the import path as necessary

// Generating the Pre-signed URL: Using these details, the backend uses AWS SDK to generate
// a pre-signed URL for the S3 bucket where the file should be uploaded.
// The URL generation is based on the file name, MIME type, and intended operations
// (like PUT for upload).
export const getPresignedImgUrl = async (
  fileName,
  mimeType,
  fileSize,
  sourceId
) => {
  // Prepare the request payload
  const data = {
    fileName,
    mimeType,
    fileSize,
    sourceId, // Include the userId in the request payload
  };
  // Construct the request configuration, utilizing the custom Axios instance and REQUEST_METHOD
  try {
    const response = await Axios.fetch({
      url: "/uploads/generate-presigned-url", // Ensure the endpoint matches your server's route
      method: REQUEST_METHOD.POST, // Using the POST method as defined in your REQUEST_METHOD object
      data, // Passing the payload
      headers: {
        "Content-Type": "application/json", // Setting the Content-Type as application/json
      },
    });
    // Log the response data for debugging
    console.log("Pre-signed URL generated:", response.data);
    return response.data; // Return the response data
  } catch (error) {
    console.error("Error generating pre-signed URL:", error);
    throw error; // Propagate the error for handling upstream
  }
};

// (like PUT for upload).
export const getPresignedVideoUrl = async (
  fileName,
  mimeType,
  fileSize,
  sourceId
) => {
  // Prepare the request payload
  const data = {
    fileName,
    mimeType,
    fileSize,
    sourceId, // Add this line to include the userId in the request payload
  };
  // Construct the request configuration, utilizing the custom Axios instance and REQUEST_METHOD
  try {
    const response = await Axios.fetch({
      url: "/uploads/generate-presigned-video-url", // Ensure the endpoint matches your server's route
      method: REQUEST_METHOD.POST, // Using the POST method as defined in your REQUEST_METHOD object
      data, // Passing the payload
      headers: {
        "Content-Type": "application/json", // Setting the Content-Type as application/json
      },
    });
    // Log the response data for debugging
    console.log("Pre-signed URL generated:", response.data);
    return response.data; // Return the response data
  } catch (error) {
    console.error("Error generating pre-signed URL:", error);
    throw error; // Propagate the error for handling upstream
  }
};

export function apiRecordVideoDataDatabase(skitData) {
  console.log(`Making API call to: ${config.baseURL}uploads/record-user-skit`);
  console.log("Skit data to record:", skitData);
  return Axios.fetch({
    url: "/uploads/record-user-skit", // Make sure the path is correct
    method: REQUEST_METHOD.POST,
    data: skitData, // Correctly passing skitData as the request payload
  });
}
export function apiRecordProfileImageUrlCrud(userData) {
  return Axios.fetch({
    url: "/uploads/record-cloudfront-user-imgurl", // Ensure this endpoint on your backend can handle updates based on username
    method: REQUEST_METHOD.POST,
    data: userData, // Correctly passing skitData as the request payload
  });
}
// Assuming currentUser contains the ID of the user
export function apiFetchCurrentUserDetails(userId) {
  return Axios.fetch({
    url: `/users/get-currentuser-data/${userId}`,
    method: REQUEST_METHOD.GET,
  });
}

export function fetchSourceUserDetailsById(userId) {
  return Axios.fetch({
    url: `/users/get-sourceuser-data/${userId}`,
    method: REQUEST_METHOD.GET,
  });
}

export function apiRecordThumbnailPreference(userId, thumbnailPreference) {
  return Axios.fetch({
    url: `/users/update-thumbnailpreferences/${userId}`,
    method: REQUEST_METHOD.PATCH,
    data: {
      thumbnailPreference,
    },
  });
}
export function apiRecordThumbnailEmotionconId(userId, emotionconId) {
  console.log("API request parameters:", { userId, emotionconId }); // Log parameters before request
  return Axios.fetch({
    url: `/users/update-emotionconId/${userId}`,
    method: REQUEST_METHOD.PATCH,
    data: {
      emotionconId,
    },
  });
}

export function apiGetListFromSourceID(sourceId) {
  return Axios.fetch({
    url: `/users/fetch-sourceId-skitList/${sourceId}`,
    method: REQUEST_METHOD.GET,
    data: {
      sourceId,
    },
  });
}

export async function apiRecordSourceIDActivityView(
  sourceId,
  activity,
  relatedId,
  targetId
) {
  return Axios.fetch({
    url: `/activity/record-sourceId-viewedSkit-activity/${sourceId}`,
    method: REQUEST_METHOD.PUT,
    data: {
      sourceId, // Current user's ID
      activity, // 'viewed'
      relatedId, // Skit's ID
      targetId, // Skit owner's ID
    },
  })
    .then((response) => {
      if (response.status === 200) {
        return response.data; // sending the data back
      } else {
        throw new Error("Failed to update view count");
      }
    })
    .catch((error) => {
      // Handling errors in the fetch operation
      console.error("API error:", error);
      throw error; // Propagating error for further handling
    });
}
export async function apiRecordSourceIDActivityClap(
  sourceId,
  activity,
  relatedId,
  targetId
) {
  return Axios.fetch({
    url: `/activity/record-sourceId-clapSkit-activity/${sourceId}`,
    method: REQUEST_METHOD.PUT,
    data: {
      sourceId, // Current user's ID
      activity, // 'clapped'
      relatedId, // Skit's ID
      targetId, // Skit owner's ID
    },
  })
    .then((response) => {
      if (response.status === 200) {
        return response.data; // sending the data back
      } else {
        throw new Error("Failed to update view count");
      }
    })
    .catch((error) => {
      // Handling errors in the fetch operation
      console.error("API error:", error);
      throw error; // Propagating error for further handling
    });
}

export async function apiRecordUserSkitNotInterested({
  sourceId,
  activity,
  relatedId,
  targetId,
}) {
  return Axios.fetch({
    url: `/activity/record-sourceId-notInterested-activity/${sourceId}`,
    method: REQUEST_METHOD.PUT, // Use the custom method via your Axios class
    data: {
      sourceId, // Current user's ID
      activity, // 'notInterested'
      relatedId, // Skit's ID
      targetId, // Skit owner's ID
    },
  });
}

export async function apiFetchUserViewedSkitActivity({
  sourceId,
  activity,
  relatedId,
}) {
  return Axios.fetch({
    url: `/activity/sourceId-viewedSkit-activity-vote-permissions/${sourceId}`,
    method: REQUEST_METHOD.GET,
    params: {
      // Use params for GET requests to pass query parameters
      sourceId, // Current user's ID
      activity, // 'viewed'
      relatedId, // Skit's ID
    },
  })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error("Failed to check view eligibility");
      }
    })
    .catch((error) => {
      console.error("API error:", error);
      throw error;
    });
}
// Function to log a user's voting activity
export const apiRecordSourceIDActivityRatings = async (recordSkitRating) => {
  try {
    const response = await Axios.fetch({
      url: `/activity/log-user-skit-rating`,
      method: REQUEST_METHOD.POST,
      data: recordSkitRating,
    });
    console.log("Activity logged successfully:", response.data);
    return response.data;
  } catch (error) {
    console.error("Failed to log activity:", error);
    throw error;
  }
};

export async function apiFetchUserRatedSkitActivity({ relatedId, offset = 0, limit = 10  }) {
  return Axios.fetch({
    url: `/activity/ratedSkit-activity/${relatedId}`,
    method: REQUEST_METHOD.GET,
    params: {
      offset,
      limit,
      activity: 'rated' // Ensure activity is correctly passed
    }
  })
    .then((response) => {
      if (response.status === 200) {
        return response.data; // This would ideally be an array of user details who have rated the skit
      } else {
        throw new Error("Failed to fetch user ratings.");
      }
    })
    .catch((error) => {
      console.error("API error:", error);
      throw error;
    });
}

export async function apiFetchUserSkitNotInterested({
  sourceId,
  activity,
  relatedId,
}) {
  return Axios.fetch({
    url: `/activity/notInterested-activity/${sourceId}`,
    method: REQUEST_METHOD.GET,
    params: {
      // Use params for GET requests to pass query parameters
      sourceId, // Current user's ID
      activity, // 'notInterested'
      relatedId, // Skit's ID
    },
  })
    .then((response) => {
      if (response.status === 200) {
        return response.data; // This would ideally be an array of user details who have notInterested the skit
      } else {
        throw new Error("Failed to fetch user ratings.");
      }
    })
    .catch((error) => {
      console.error("API error:", error);
      throw error;
    });
}

export async function apiUnSusbcribeUser({ sourceId, activity, targetId }) {
  return Axios.fetch({
    url: `/activity/unsubscribe/${sourceId}/${targetId}`, // Corrected URL format
    method: REQUEST_METHOD.DELETE, // Use the custom method via your Axios class
    data: {
      sourceId, // Subscriber
      activity,
      targetId, // Subscription id (the user)
    },
  });
}
export async function apiRecordUserSusbcribeActivity({
  sourceId,
  activity,
  targetId,
  notificationMessage = "You have a new subscriber!", // Default value
}) {
  return Axios.fetch({
    url: `/activity/subscribed/${sourceId}/${targetId}`, // Corrected URL format
    method: REQUEST_METHOD.POST, // Use the custom method via your Axios class
    data: {
      sourceId, // Subscriber (user who is subscribing)
      activity,
      targetId, // Subscription id (the user)
      notificationMessage,
    },
  });
}
export async function apiFetchUserSubscriptionStatus({
  sourceId,
  activity,
  targetId,
}) {
  return Axios.fetch({
    url: `/activity/subscribed/status/${sourceId}/${targetId}`, // Adjust URL as needed
    method: REQUEST_METHOD.GET,
    params: {
      // Use params for GET requests to pass query parameters
      sourceId, // Current user's ID
      activity, // 'subscribed'
      targetId, // user ID
    },
  });
}
export async function apiFetchUserSubscribers({
  targetId,
  offset = 0,
  limit = 10,
}) {
  return Axios.fetch({
    url: `/activity/subscribers/${targetId}`,
    method: REQUEST_METHOD.GET,
    params: { offset, limit },
  });
}
export async function apiFetchUserSubscriptions({
  sourceId,
  offset = 0,
  limit = 10,
}) {
  return Axios.fetch({
    url: `/activity/subscriptions/${sourceId}`,
    method: REQUEST_METHOD.GET,
    params: { offset, limit },
  });
}

export async function apifetchCurrentUserNotifications({ userId }) {
  return Axios.fetch({
    url: `/users/notifications/${userId}`,
    method: REQUEST_METHOD.GET,
  });
}
export async function apiMarkNotificationAsRead(notificationId) {
  return Axios.fetch({
    url: `/users/notifications/mark-read/${notificationId}`,
    method: REQUEST_METHOD.PUT,
  });
}

export async function apiMarkAllNotificationsAsRead(userId) {
  return Axios.fetch({
    url: `/users/notifications/clear-all/${userId}`,
    method: REQUEST_METHOD.PUT,
  });
}
export async function apiUpdateRemoteColor(userId, remoteColor) {
  return Axios.fetch({
    url: `/users/update-remote-color/${userId}`,
    method: REQUEST_METHOD.PUT,
    data: { remoteColor },
  });
}
export async function apiFetchCurrentUserRemoteColorSetting(userId) {
  return Axios.fetch({
    url: `/users/fetch-user-remote-color-setting/${userId}`,
    method: REQUEST_METHOD.GET,
  });
}

export async function apiSearchMembersByUsername({ membersUserName }) {
  try {
    const response = await Axios.fetch({
      url: `/users/search-members`,
      method: REQUEST_METHOD.GET,
      params: { username: membersUserName },
    });
    if (response.status === 200) {
      return response.data; // This should be an array of user details
    } else {
      throw new Error("Failed to fetch users.");
    }
  } catch (error) {
    console.error("API error:", error);
    throw error;
  }
}

export const apiFetchCurrentUserAttributes = (userId) => {
  return Axios.fetch({
    url: `/users/current-user/${userId}`,
    method: REQUEST_METHOD.GET,
    params: { userId },
  });
};

export default {
  apiRecordUserSusbcribeActivity,
  apiFetchUserRatedSkitActivity,
  apiFetchUserSubscriptions,
  apiFetchUserSubscribers,
  apiFetchUserSubscriptionStatus,
  apiSearchMembersByUsername,
  apiRecordSourceIDActivityClap,
  apiFetchCurrentUserRemoteColorSetting,
  apiUpdateRemoteColor,
  apiMarkNotificationAsRead,
  apiMarkAllNotificationsAsRead,
  apifetchCurrentUserNotifications,
  apiUnSusbcribeUser,
  getPresignedImgUrl,
  apiRecordVideoDataDatabase,
  apiRecordProfileImageUrlCrud,
  apiFetchCurrentUserDetails,
  apiRecordThumbnailEmotionconId,
  apiRecordThumbnailPreference,
  apiGetListFromSourceID,
  apiRecordSourceIDActivityView,
  apiFetchUserViewedSkitActivity,
  apiRecordSourceIDActivityRatings,
  apiRecordUserSkitNotInterested,
  apiFetchUserSkitNotInterested,
};
