export const emoteOptions = [
  { id: 0, emojiChoice: "yay" },
  { id: 1, emojiChoice: "wink-heart-kiss" },
  { id: 2, emojiChoice: "heart-eyes-fac" },
  { id: 3, emojiChoice: "zipper-face" },
  { id: 4, emojiChoice: "tongue-laugh" },
  { id: 5, emojiChoice: "teethy-laugh" },
  { id: 6, emojiChoice: "serious" },
  { id: 7, emojiChoice: "wow" },
];

export const AllEmoteOptions = [
  { id: 0, name: "awesome", emoteChoice: "yay" },
  { id: 1, name: "kiss", emoteChoice: "wink-heart-kiss" },
  { id: 2, name: "love", emoteChoice: "heart-eyes-face" },
  { id: 3, name: "quiet", emoteChoice: "zipper-face" },
  { id: 4, name: "sad", emoteChoice: "sad" },
  { id: 5, name: "annoyed", emoteChoice: "annoyed" },
  { id: 6, name: "lmfao", emoteChoice: "tongue-laugh" },
  { id: 7, name: "funny", emoteChoice: "teethy-laugh" },
  { id: 8, name: "serious", emoteChoice: "serious" },
  { id: 9, name: "surprise", emoteChoice: "wow" },
  { id: 10, name: "angry", emoteChoice: "angry" },
];
