import React, { useState, useEffect, forwardRef } from "react";
import classNames from "classnames"; // Ensure to import this library
import "./stripeAnimationBtn.scss";
//  stripeAnimationBtnwidth:"188px"
//       stripeAnimationBtnFontColor:"blood-red"
const StripeAnimationBtn = forwardRef(
  (
    {
      onClick,
      disabled = false,
      isSubmit = false,
      stripeAnimationBtnText = "",
      stripeAnimationBtnImage = "",
      stripeAnimationBtnfontSize = "1.3rem",
      stripeAnimationBtnwidth,
      stripeAnimationBtnheight,
      stripeAnimationBtnfontFamily = "'Roboto Slab', serif",
      stripeAnimationBtnfontWeight = "800",
      stripeAnimationBtnFontColor = "#232f5f",
      stripeAnimationBtnmarginLeft = "0px",
      stripeAnimationBtnmarginRight = "0px",
      stripeAnimationBtnBorderColor,
      stripeAnimationBtnBorderWidth = "1px",
      stripeAnimationBtnBorderRadius,
      stripeAnimationBtnmarginTop,
      stripeAnimationBtnPosition,
      stripeBtnAnimationActive = false,
      active = false,
      extraStyles = {},
      stripeAnimationBtnPaddingTop,
      stripeAnimationBtnPaddingBottom,
      stripeAnimationBtnPaddingLeft,
      stripeAnimationBtnPaddingRight,
      className,
      imageOnTop = false, // Default value set to false
      reverseOrder = true, // puts the icon first horizontally
      bottom,
      top,
      backgroundFilter,
      backgroundColor,
      textStyle,
      boxShadowStyle,
      icon = null,
      left,
    },
    ref
  ) => {
    // The introduction of isAnimating as a local state variable ensures that the animation
    // status is controlled directly within the component. Therefore not animating on mount
    const [isAnimating, setIsAnimating] = useState(false);
    // Function to initiate glow effect
    // This function is used to activate the animation. It sets isAnimating to true,
    //  which triggers the animation. After a delay of 2000 milliseconds (2 seconds),
    // it resets isAnimating to false. This timeout ensures the animation only plays
    // for a short duration immediately after being triggered from the onclick call back
    const handleGlowEffect = () => {
      setIsAnimating(true);
      setTimeout(() => setIsAnimating(false), 2000); // reset animation state after 2 seconds
    };

    const handleClick = (e) => {
      e.preventDefault(); // Prevent default if necessary
      handleGlowEffect(); // Trigger animation
      if (onClick) onClick(e); // Call external onClick function if provided
    };

    const btnClasses = classNames(
      "btn", // Always apply 'btn' class
      className, // for localized prop passing
      {
        animating: isAnimating,
        "glow-on-animation": isAnimating || stripeBtnAnimationActive,
        "btn-active-orange": active,
        "glow-on-press": isAnimating,
        "show-animation": isAnimating,
        disabled: disabled, // Add a disabled class if the button is disabled
      }
    );
    const dynamicStyle = disabled
      ? { color: "black", cursor: "not-allowed", ...extraStyles } // Apply disabled styles
      : { ...extraStyles };

    return (
      <button
        ref={ref}
        disabled={disabled}
        type={isSubmit ? "submit" : "button"}
        className={btnClasses}
        onMouseDown={handleGlowEffect} // handleGlowEffect is triggered on mouse down to simulate :active state.
        onClick={handleClick} // Unified click handler
        style={{
          boxShadowStyle,
          fontSize: stripeAnimationBtnfontSize,
          fontFamily: stripeAnimationBtnfontFamily,
          fontWeight: stripeAnimationBtnfontWeight,
          color: stripeAnimationBtnFontColor,
          width: stripeAnimationBtnwidth,
          height: stripeAnimationBtnheight,
          marginLeft: stripeAnimationBtnmarginLeft,
          marginRight: stripeAnimationBtnmarginRight,
          marginTop: stripeAnimationBtnmarginTop,
          borderColor: stripeAnimationBtnBorderColor,
          borderWidth: stripeAnimationBtnBorderWidth, 
          borderRadius: stripeAnimationBtnBorderRadius,
          paddingTop: stripeAnimationBtnPaddingTop,
          paddingBottom: stripeAnimationBtnPaddingBottom,
          paddingLeft: stripeAnimationBtnPaddingLeft,
          paddingRight: stripeAnimationBtnPaddingRight,
          position: stripeAnimationBtnPosition,
          backgroundFilter: backgroundFilter,
          backgroundColor: backgroundColor,
          top: top,
          left: left,
          bottom: bottom,
          textStyle,
          ...extraStyles, // spread the styles at the end to not overirde the css locally
          ...dynamicStyle, // Apply dynamic styles based on the button state
        }}
      >
        <div
          className="TopReverse"
          style={{
            display: "flex",
            flexDirection: imageOnTop
              ? "column"
              : reverseOrder
                ? "row-reverse"
                : "row",
            alignItems: "center",
          }}
        >
          {imageOnTop && stripeAnimationBtnImage && (
            <div className="icon-container">{stripeAnimationBtnImage}</div>
          )}
          {icon && (
            <div className="icon-container">{icon}</div> // This is where the icon gets rendered
          )}
          <span style={{ ...textStyle }}>{stripeAnimationBtnText}</span>
          {/* Apply textStyle here */}
          {!imageOnTop && stripeAnimationBtnImage && (
            <div className="icon-container">{stripeAnimationBtnImage}</div>
          )}
        </div>
      </button>
    );
  }
);
export default StripeAnimationBtn;
