import "./insetButton.scss";

const InsetButtonEventListener = ({
  image = null,
  btnText,
  className = "",
  onClick,
  textClassName = "", 
  textStyles = {}, // Default empty object if not provided
  style = {}, 
  height,
}) => {
  return (
    <button
      className={`globalized-btn position-relative ${className}`}
      onClick={(e) => {
        if (onClick) onClick(e);
      }}
      style={{ ...style, height }}  // Apply the inline style passed through props
    >
      {image}
      <span className={`${textClassName}`} style={textStyles}>{btnText}</span>
    </button>
  );
};

export default InsetButtonEventListener;
