import React, { useState, useEffect } from "react";
import classNames from "classnames"; // Ensure to import this library
import "./whiteBtn.scss";

const WhiteBtn = React.forwardRef(
  (
    {
      onClick,
      disabled = false,
      isSubmit = false,
      whiteBtnText = "",
      whiteBtnImage = "",
      whiteBtnfontSize = "1.3rem",
      whiteBtnwidth,
      whiteBtnheight,
      whiteBtnfontFamily = "'Roboto Slab', serif",
      whiteBtnfontWeight = "800",
      whiteBtnFontColor = "#232f5f",
      whiteBtnmarginLeft = "0px",
      whiteBtnmarginRight = "0px",
      whiteBtnBorderColor,
      whiteBtnBorderWidth = "1px",
      whiteBtnBorderRadius,
      whiteBtnmarginTop,
      whiteBtnPosition,
      whiteBtnAnimationActive = false,
      active = false,
      isAnimating = false, // Ensure default is false if not provided
      animations = {}, // Pass an object to toggle animations
      extraStyles = {},
      whiteBtnPaddingTop,
      whiteBtnPaddingBottom,
      whiteBtnPaddingLeft,
      whiteBtnPaddingRight,
      className,
      imageOnTop = false, // Default value set to false
      reverseOrder = true, // puts the icon first horizontally
      bottom,
      top,
      backgroundFilter,
      backgroundColor,
      textStyle,
      boxShadowStyle,
      left,
      right,
    },
    ref
  ) => {
    // Will activate animations locally
    const [localAnimations, setLocalAnimations] = useState({
      glow: false, // If the default behavior for glow is set to true it will animate without clicking
    });

    useEffect(() => {
      setLocalAnimations((prev) => ({ ...prev, ...animations }));
    }, []); // Updated dependency array to respond to animations prop changes

    const handleGlowEffect = () => {
      if (!whiteBtnAnimationActive) {
        return; // Do not proceed if animation is not active
      }
      setLocalAnimations({ glow: true });
      setTimeout(() => {
        setLocalAnimations((prev) => ({ ...prev, glow: false }));
      }, 2000);
    };

    const btnClasses = classNames(
      "btn", // Always apply 'btn' class
      className, // for localized prop passing
      {
        animating: isAnimating,
        "glow-on-animation": whiteBtnAnimationActive || localAnimations.glow,
        "btn-active-orange": active,
        "glow-on-press": localAnimations.glow,
        "show-animation": localAnimations.glow,
      }
    );

    return (
      <button
        ref={ref}
        disabled={disabled}
        type={isSubmit ? "submit" : "button"}
        className={btnClasses}
        onMouseDown={handleGlowEffect} // handleGlowEffect is triggered on mouse down to simulate :active state.
        onClick={(e) => {
          if (onClick) onClick(e);
        }}
        style={{
          boxShadowStyle,
          fontSize: whiteBtnfontSize,
          fontFamily: whiteBtnfontFamily,
          fontWeight: whiteBtnfontWeight,
          color: whiteBtnFontColor,
          width: whiteBtnwidth,
          height: whiteBtnheight,
          marginLeft: whiteBtnmarginLeft,
          marginRight: whiteBtnmarginRight,
          marginTop: whiteBtnmarginTop,
          borderColor: whiteBtnBorderColor,
          borderWidth: whiteBtnBorderWidth,
          borderRadius: whiteBtnBorderRadius,
          paddingTop: whiteBtnPaddingTop,
          paddingBottom: whiteBtnPaddingBottom,
          paddingLeft: whiteBtnPaddingLeft,
          paddingRight: whiteBtnPaddingRight,
          position: whiteBtnPosition,
          backgroundFilter: backgroundFilter,
          backgroundColor: backgroundColor,
          top: top,
          right:right,
          left:left,
          bottom: bottom,
          textStyle,
          ...extraStyles, // spread the styles at the end to not overirde the css locally

        }}
      >
        <div
          className="TopReverse"
          style={{
            display: "flex",
            flexDirection: imageOnTop
              ? "column"
              : reverseOrder
              ? "row-reverse"
              : "row",
            alignItems: "center",
          }}
        >
          {imageOnTop && whiteBtnImage && (
            <div className="icon-container">{whiteBtnImage}</div>
          )}
          <span style={{ ...textStyle }}>{whiteBtnText}</span>
          {/* Apply textStyle here */}
          {!imageOnTop && whiteBtnImage && (
            <div className="icon-container">{whiteBtnImage}</div>
          )}
        </div>
      </button>
    );
  }
);

export default WhiteBtn;
