import React from "react";
import "./rectangleNeumorphicBtn.scss";

const RectangleNeumorphicBtn = ({
  children,
  onClick,
  fontSizeRectangleNeumorphicBtn,
  fontFamilyRectangleNeumorphicBtn,
  fontWeightRectangleNeumorphicBtn,
  colorRectangleNeumorphicBtn,
  paddingRectangleNeumorphicBtn,
  borderRadiusRectangleNeumorphicBtn,
  boxShadowRectangleNeumorphicBtn,
  backgroundColorRectangleNeumorphicBtn,
  borderRectangleNeumorphicBtn,
  outlineRectangleNeumorphicBtn,
  textRectangleNeumorphicBtn,
  image,
}) => {
  const buttonStyle = {
    fontSize: fontSizeRectangleNeumorphicBtn,
    fontFamily: fontFamilyRectangleNeumorphicBtn,
    fontWeight: fontWeightRectangleNeumorphicBtn,
    color: colorRectangleNeumorphicBtn,
    padding: paddingRectangleNeumorphicBtn,
    borderRadius: borderRadiusRectangleNeumorphicBtn,
    boxShadow: boxShadowRectangleNeumorphicBtn,
    backgroundColor: backgroundColorRectangleNeumorphicBtn,
    border: borderRectangleNeumorphicBtn,
    outline: outlineRectangleNeumorphicBtn,
    cursor: "pointer",
  };

  return (
    <button className="neumorphic-button" onClick={onClick} style={buttonStyle}>
      {image && <div className="button-image">{image}</div>}
      {textRectangleNeumorphicBtn}
      {children}
    </button>
  );
};

export default RectangleNeumorphicBtn;
