import React, { useState } from "react";
import {
  LeftArrowIcon,
  UpArrowIcon,
  RightArrowIcon,
  DownArrowIcon,
} from "../icons";
import "./bigRndBtn.scss";

const BigRemoteButton = ({
  onMainClick,
  onLeftClick,
  onRightClick,
  onUpClick,
  onDownClick,
  mainBtnText,
}) => {
  const [pressed, setPressed] = useState(false);

  const handleMouseDown = () => {
    setPressed(true);
    onMainClick(); // If you want to trigger the click action on mouse down
    setTimeout(() => setPressed(false), 500); // Adjust timing as needed
  };

  const handleMouseUp = () => {
    setPressed(false);
  };
  return (
    <div
      name="big-main-btn"
      className={`remote-btn-main ${pressed ? "pressed-btn-effect" : ""}`}
      onClick={onMainClick}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp} // Ensures the effect is removed if cursor leaves the button
      style={{ cursor: "pointer" }}
    >
      <div id="bigRndBtn">
        <div name="leftarrow-btn" className="menu-left" onClick={onLeftClick}>
          <LeftArrowIcon className="arrow-menu" />
        </div>
        <div className="menu-middle" onClick={onUpClick}>
          <div name="uparrow-btn" className="uparrow">
            <UpArrowIcon className="arrow-menu" />
          </div>
        </div>
        <div name="menunav-btn" className="btn-menu-text">
          <h4>{mainBtnText}</h4>
        </div>
        <div name="rightarrow-btn" onClick={onRightClick}>
          <RightArrowIcon className="arrow-menu menu-right" />
        </div>
        <div className="menu-middle" onClick={onDownClick}>
          <div name="downarrow-btn" className="downarrow">
            <DownArrowIcon className="arrow-menu" />
          </div>
        </div>
      </div>
    </div>
  );
};
export default BigRemoteButton;
