import React, { useState, useEffect } from "react";
import "./glassBtn.scss";

const GlassBtn = ({
  reverseOrder = false,
  imageOnTop = false,
  glassBtnText,
  onClick,
  glassBtnWidth = "200px",
  fullwidthGlassHi = false,
  sparkle = false,
  glassConfirm = false,
  className = "glass-btn",
  disabled = false,
  isSubmit = false,
  glassBtnImage = "",
  glassBtnTextDecorationColor = "#964B00",
  glassBtnTextFontSize = ".75rem",
  glassBtnFontFamily = "roboto-slab",
  glassBtnFontWeight = "900",
  glassBtnFontColor = "#964B00",
  glassBtnHeight = "37px",
  glassBtnPosition = "relative",
  glassBtnTextDecoration = "underline",
  glassBtnTextAlign = "center",
  glassBtnTextShadow = "0 0 black",
  glassBtnBorderRadius = "8px",
  glassBtnTransition = "all 0.1s ease-in-out",
  glassBtnOutline = "0",
  glassBtnLineHeight = "20px",
  glassBtnMarginLeft = "auto",
  glassBtnMarginRight = "auto",
  glassBtnMarginTop,
  glassBtnBoxShadow,
  hi2Style = {},
  hi3Style = {},
  localHi2Class = "",
  localHi3Class = "",
  glassHoverStyle = {},
  top,
  extraStyles = {},
  animations = {},
  textLocalStyle = {},
}) => {
  const [localAnimations, setLocalAnimations] = useState({
    glow: false,
  });

  useEffect(() => {
    setLocalAnimations((prev) => ({ ...prev, ...animations }));
  }, []);

  const handleGlowEffect = (e) => {
    e.persist();
    setLocalAnimations({ glow: true });
    setTimeout(() => {
      setLocalAnimations((prev) => ({ ...prev, glow: false }));
    }, 2000);
  };

  return (
    <div
      className={`button glass ${fullwidthGlassHi ? "fullwidth-glasshi" : ""} ${
        sparkle ? "sparkle" : ""
      } ${glassConfirm ? "glassConfirm" : ""} 
  ${
    localAnimations.glow
      ? "glow-on-glass-animation glow-on-press show-animation"
      : ""
  }`}
      onClick={(e) => {
        handleGlowEffect(e);
        if (onClick) onClick(e);
      }}
      disabled={disabled}
      type={isSubmit ? "submit" : "button"}
      style={{
        width: glassBtnWidth,
        height: glassBtnHeight,
        boxShadow: `${glassBtnBoxShadow} !important`,
        position: glassBtnPosition,
        textDecoration: glassBtnTextDecoration,
        textDecorationColor: glassBtnTextDecorationColor,
        textAlign: glassBtnTextAlign,
        textShadow: glassBtnTextShadow,
        borderRadius: glassBtnBorderRadius,
        transition: glassBtnTransition,
        outline: glassBtnOutline,
        lineHeight: glassBtnLineHeight,
        marginLeft: glassBtnMarginLeft,
        marginRight: glassBtnMarginRight,
        marginTop: glassBtnMarginTop,
        top: top,
        ...extraStyles,
      }}
    >
      <div
        className="content"
        style={{
          display: "flex",
          flexDirection: imageOnTop ? "column" : "row",
          alignItems: "center",
        }}
      >
        {imageOnTop && glassBtnImage && (
          <div className="icon-container">{glassBtnImage}</div>
        )}
        {!reverseOrder && !imageOnTop && glassBtnImage && (
          <div className="icon-container">{glassBtnImage}</div>
        )}
        <span
          style={{
            color: glassBtnFontColor,
            fontSize: glassBtnTextFontSize,
            fontFamily: glassBtnFontFamily,
            fontWeight: glassBtnFontWeight,
            ...textLocalStyle,
          }}
        >
          {glassBtnText}
        </span>
        {reverseOrder && !imageOnTop && glassBtnImage && (
          <div className="icon-container">{glassBtnImage}</div>
        )}
      </div>
      <div
        className="glasshover"
        style={{
          ...{ width: `calc(${glassBtnWidth} - 2px)` },
          ...glassHoverStyle,
        }}
      ></div>
      <div
        className={`glassref ${className}`}
        style={{
          width: glassBtnWidth,
        }}
      />
      <div id="hi2" className={`glasshi ${localHi2Class}`} style={hi2Style} />
      <div id="hi3" className={`glasshi ${localHi3Class}`} style={hi3Style} />
    </div>
  );
};

export default GlassBtn;
