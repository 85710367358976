import React, { useState } from "react";

const SquareFlatBtns = ({
  name,
  onClick,
  Icon,
  title,
  className = "",
  SqaureBtnbackgroundColor = "#1c1c1c",
  border = "0.2px solid #c79f27",
  extraStyles = {},
  iconWidth,
  iconHeight,
  buttonWidth,
  buttonHeight,
  borderRadius = "4px",
  titleStyle = {},
}) => {
  const [pressed, setPressed] = useState(false);
  const handleClick = () => {
    setPressed(true);
    onClick(); // Fire the click action
    setTimeout(() => setPressed(false), 500); // Reset the pressed state after a delay
  };
  const buttonStyles = {
    backgroundColor: SqaureBtnbackgroundColor,
    border,
    width:buttonWidth,
    height:buttonHeight,
    cursor: "pointer",
    boxShadow: pressed
      ? "inset 0 0 10px 0px rgba(0,0,0,0.5)"
      : "0 0 10px 0px rgba(0, 0, 0, 0.5)",
    transition: "background-color 1s cubic-bezier(0.075, 0.82, 0.165, 1)", 
    ...(borderRadius && { borderRadius }),
    ...extraStyles,
  };

  return (
    <div
      name={name}
      className={`remote-btn2 ${className} ${pressed ? "pressed-btn-effect" : ""}`}
      onMouseDown={() => setPressed(true)}
      onMouseUp={() => setPressed(false)}
      onMouseLeave={() => setPressed(false)}
      onClick={handleClick}
      style={buttonStyles}
    >
      {/* // Now passing a style object to
    control icon size plus content styles that can be changed locally*/}
      {Icon && <Icon width={iconWidth} height={iconHeight} />}
      <p style={titleStyle}>{title}</p> {/* Applying titleStyle here */}
    </div>
  );
};

export default SquareFlatBtns;
