import React from "react";
import { BellIcon } from "../icons";
import { AnimatedBellIcon } from "../icons/animatedBellIcon";
import { useNotifications } from "../context/notificationContext";

const NotificationBell = () => {
  const { unreadCount } = useNotifications();

  return (
    <div style={{ position: "relative" }}>
      {unreadCount > 0 ? (
        <AnimatedBellIcon
          fillColor="#b03a2e"
          width="20px"
          height="20px"
          unreadCount={unreadCount}
        />
      ) : (
        <BellIcon fillColor="#b03a2e" width="20px" height="20px" />
      )}
      {unreadCount > 0 && (
        <span
          style={{
            position: "absolute",
            top: "-5px",
            right: "-5px",
            background: "red",
            color: "white",
            borderRadius: "50%",
            padding: "2px 6px",
            fontSize: "10px",
          }}
        >
          {unreadCount}
        </span>
      )}
    </div>
  );
};

export default NotificationBell;
