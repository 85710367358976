import React from "react";
import { RoundFlatBtns } from "./index";
import {
  DynamicCircleIcon,
  DynamicCircleLindearColorsIcon,
} from "../icons/index";
import { themeContainerStyleColors } from "../../constants/globalColorParameters";

const ThemedButtonColorPalette = ({
  setTheme,
  className,
  remoteColorUserSetting,
  currentUser,
}) => {
  const changeTheme = (newTheme) => {
    setTheme(newTheme);
    if (currentUser) {
      remoteColorUserSetting(currentUser, newTheme); // Update the remote color in the backend
    }
  };

  return (
    <div
      className={`themed-button-palette ${className}`}
      style={gridContainerStyle}
    >
      <RoundFlatBtns
        onClick={() => changeTheme("glass")}
        Icon={DynamicCircleIcon} // Pass component type
        iconProps={{ fillColor: themeContainerStyleColors.glass }}
        title="Glass"
        titleStyle={{ fontSize: ".6rem", margin: "0", textAlign: "center" }}
        className="remote-btn2"
        width="40px"
        height="40px"
        extraStyles={{
          padding: "1px",
        }}
      />
      <RoundFlatBtns
        onClick={() => changeTheme("blue")}
        Icon={DynamicCircleIcon}
        iconProps={{ fillColor: themeContainerStyleColors.blue }}
        title="Blue"
        titleStyle={{ fontSize: ".6rem", margin: "0", textAlign: "center" }}
        className="remote-btn2"
        width="40px"
        height="40px"
        extraStyles={{
          padding: "1px",
        }}
      />
      <RoundFlatBtns
        onClick={() => changeTheme("redish")}
        Icon={DynamicCircleIcon}
        iconProps={{ fillColor: themeContainerStyleColors.redish }}
        title="Redish"
        titleStyle={{ fontSize: ".6rem", margin: "0", textAlign: "center" }}
        className="remote-btn2"
        width="40px"
        height="40px"
        extraStyles={{ padding: "1px" }}
      />
      <RoundFlatBtns
        onClick={() => changeTheme("gum")}
        Icon={DynamicCircleIcon}
        iconProps={{ fillColor: themeContainerStyleColors.gum }}
        title="Gum"
        titleStyle={{ fontSize: ".6rem", margin: "0", textAlign: "center" }}
        className="remote-btn2"
        width="40px"
        height="40px"
        extraStyles={{
          padding: "1px",
        }}
      />
      <RoundFlatBtns
        onClick={() => changeTheme("white")}
        Icon={DynamicCircleIcon}
        iconProps={{ fillColor: themeContainerStyleColors.white }}
        title="White"
        titleStyle={{ fontSize: ".6rem", margin: "0", textAlign: "center" }}
        className="remote-btn2"
        width="40px"
        height="40px"
        extraStyles={{
          padding: "1px",
        }}
      />
      <RoundFlatBtns
        onClick={() => changeTheme("ivory")}
        Icon={DynamicCircleIcon}
        iconProps={{ fillColor: themeContainerStyleColors.ivory }}
        title="Ivory"
        titleStyle={{ fontSize: ".6rem", margin: "0", textAlign: "center" }}
        className="remote-btn2"
        width="40px"
        height="40px"
        extraStyles={{
          padding: "1px",
        }}
      />
      <RoundFlatBtns
        onClick={() => changeTheme("offWhite")}
        Icon={DynamicCircleIcon}
        iconProps={{ fillColor: themeContainerStyleColors.offWhite }}
        title="Cream"
        titleStyle={{ fontSize: ".6rem", margin: "0", textAlign: "center" }}
        className="remote-btn2"
        width="40px"
        height="40px"
        extraStyles={{
          padding: "1px",
        }}
      />
      <RoundFlatBtns
        onClick={() => changeTheme("pink")}
        Icon={DynamicCircleIcon}
        iconProps={{ fillColor: themeContainerStyleColors.pink }}
        title="Pink"
        titleStyle={{ fontSize: ".6rem", margin: "0", textAlign: "center" }}
        className="remote-btn2"
        width="40px"
        height="40px"
        extraStyles={{
          padding: "1px",
        }}
      />
      <RoundFlatBtns
        onClick={() => changeTheme("brown")}
        Icon={DynamicCircleIcon}
        iconProps={{ fillColor: themeContainerStyleColors.brown }}
        title="Brown"
        titleStyle={{ fontSize: ".6rem", margin: "0", textAlign: "center" }}
        className="remote-btn2"
        width="40px"
        height="40px"
        extraStyles={{
          padding: "1px",
        }}
      />
      <RoundFlatBtns
        onClick={() => changeTheme("maroon")}
        Icon={DynamicCircleIcon}
        iconProps={{ fillColor: themeContainerStyleColors.maroon }}
        title="Maroon"
        titleStyle={{ fontSize: ".6rem", margin: "0", textAlign: "center" }}
        className="remote-btn2"
        width="40px"
        height="40px"
        extraStyles={{
          padding: "1px",
        }}
      />
      <RoundFlatBtns
        onClick={() => changeTheme("redish")}
        Icon={DynamicCircleIcon}
        iconProps={{ fillColor: themeContainerStyleColors.redish }}
        title="Redish"
        titleStyle={{ fontSize: ".6rem", margin: "0", textAlign: "center" }}
        className="remote-btn2"
        width="40px"
        height="40px"
        extraStyles={{
          padding: "1px",
        }}
      />
      <RoundFlatBtns
        onClick={() => changeTheme("bloodred")}
        Icon={DynamicCircleIcon}
        iconProps={{ fillColor: themeContainerStyleColors.bloodred }}
        title="Blood"
        titleStyle={{ fontSize: ".6rem", margin: "0", textAlign: "center" }}
        className="remote-btn2"
        width="40px"
        height="40px"
        extraStyles={{
          padding: "1px",
        }}
      />
      <RoundFlatBtns
        onClick={() => changeTheme("purple")}
        Icon={DynamicCircleIcon}
        iconProps={{ fillColor: themeContainerStyleColors.purple }}
        title="Purple"
        titleStyle={{ fontSize: ".6rem", margin: "0", textAlign: "center" }}
        className="remote-btn2"
        width="40px"
        height="40px"
        extraStyles={{
          padding: "1px",
        }}
      />
      <RoundFlatBtns
        onClick={() => changeTheme("orange")}
        Icon={DynamicCircleIcon}
        iconProps={{ fillColor: themeContainerStyleColors.orange }}
        title="Orange"
        titleStyle={{ fontSize: ".6rem", margin: "0", textAlign: "center" }}
        className="remote-btn2"
        width="40px"
        height="40px"
        extraStyles={{
          padding: "1px",
        }}
      />
      <RoundFlatBtns
        onClick={() => changeTheme("yellow")}
        Icon={DynamicCircleIcon}
        iconProps={{ fillColor: themeContainerStyleColors.yellow }}
        title="Yellow"
        titleStyle={{ fontSize: ".6rem", margin: "0", textAlign: "center" }}
        className="remote-btn2"
        width="40px"
        height="40px"
        extraStyles={{
          padding: "1px",
        }}
      />
      <RoundFlatBtns
        onClick={() => changeTheme("yellowgold")}
        Icon={DynamicCircleIcon}
        iconProps={{ fillColor: themeContainerStyleColors.yellowgold }}
        title="Golden"
        titleStyle={{ fontSize: ".6rem", margin: "0", textAlign: "center" }}
        className="remote-btn2"
        width="40px"
        height="40px"
        extraStyles={{
          padding: "1px",
        }}
      />
      <RoundFlatBtns
        onClick={() => changeTheme("military")}
        Icon={DynamicCircleIcon}
        iconProps={{ fillColor: themeContainerStyleColors.military }}
        title="Military"
        titleStyle={{ fontSize: ".6rem", margin: "0", textAlign: "center" }}
        className="remote-btn2"
        width="40px"
        height="40px"
        extraStyles={{
          padding: "1px",
        }}
      />
      <RoundFlatBtns
        onClick={() => changeTheme("corveteRed")}
        Icon={DynamicCircleIcon}
        iconProps={{ fillColor: themeContainerStyleColors.corveteRed }}
        title="Corvete"
        titleStyle={{ fontSize: ".6rem", margin: "0", textAlign: "center" }}
        className="remote-btn2"
        width="40px"
        height="40px"
        extraStyles={{
          padding: "1px",
        }}
      />
      <RoundFlatBtns
        onClick={() => changeTheme("transparent")}
        Icon={DynamicCircleIcon}
        iconProps={{ fillColor: themeContainerStyleColors.transparent }}
        title="Dark"
        titleStyle={{ fontSize: ".6rem", margin: "0", textAlign: "center" }}
        className="remote-btn2"
        width="40px"
        height="40px"
        extraStyles={{
          padding: "1px",
        }}
      />
      <RoundFlatBtns
        onClick={() => changeTheme("beige")}
        Icon={DynamicCircleIcon}
        iconProps={{ fillColor: themeContainerStyleColors.beige }}
        title="Beige"
        titleStyle={{ fontSize: ".6rem", margin: "0", textAlign: "center" }}
        className="remote-btn2"
        width="40px"
        height="40px"
        extraStyles={{
          padding: "1px",
        }}
      />
      <RoundFlatBtns
        onClick={() => changeTheme("teal")}
        Icon={DynamicCircleIcon}
        iconProps={{ fillColor: themeContainerStyleColors.teal }}
        title="Teal"
        titleStyle={{ fontSize: ".6rem", margin: "0", textAlign: "center" }}
        className="remote-btn2"
        width="40px"
        height="40px"
        extraStyles={{
          padding: "1px",
        }}
      />
      <RoundFlatBtns
        onClick={() => changeTheme("burgundy")}
        Icon={DynamicCircleIcon}
        iconProps={{ fillColor: themeContainerStyleColors.burgundy }}
        title="Wine"
        titleStyle={{ fontSize: ".6rem", margin: "0", textAlign: "center" }}
        className="remote-btn2"
        width="40px"
        height="40px"
        extraStyles={{
          padding: "1px",
        }}
      />
      <RoundFlatBtns
        onClick={() => changeTheme("steal")}
        Icon={() => <DynamicCircleLindearColorsIcon theme="steal" />}
        title="Steal"
        titleStyle={{ fontSize: ".6rem", margin: "0", textAlign: "center" }}
        // themeClassName={isGradientTheme ? "theme-steal" : ""}
        className="remote-btn2"
        width="40px"
        height="40px"
        extraStyles={{
          padding: "1px",
        }}
      />
      <RoundFlatBtns
        onClick={() => changeTheme("jean")}
        Icon={() => <DynamicCircleLindearColorsIcon theme="jean" />}
        title="Jean"
        titleStyle={{ fontSize: ".6rem", margin: "0", textAlign: "center" }}
        // themeClassName={isGradientTheme ? "theme-jean" : ""} // this chages the button color, i can just use a specific parameter to change each button color
        className="remote-btn2"
        width="40px"
        height="40px"
        extraStyles={{
          padding: "1px",
        }}
      />
      <RoundFlatBtns
        onClick={() => changeTheme("greyish")}
        Icon={() => <DynamicCircleLindearColorsIcon theme="greyish" />}
        title="Greyish"
        titleStyle={{ fontSize: ".6rem", margin: "0", textAlign: "center" }}
        // themeClassName={isGradientTheme ? "theme-greyish" : ""}
        className="remote-btn2"
        width="40px"
        height="40px"
        extraStyles={{
          padding: "1px",
        }}
      />
      <RoundFlatBtns
        onClick={() => changeTheme("gold")}
        Icon={DynamicCircleIcon}
        iconProps={{ fillColor: themeContainerStyleColors.gold }}
        title="Gold"
        titleStyle={{ fontSize: ".6rem", margin: "0", textAlign: "center" }}
        className="remote-btn2"
        width="40px"
        height="40px"
        extraStyles={{
          padding: "1px",
        }}
      />
    </div>
  );
};
// Style for the grid container
const gridContainerStyle = {
  display: "grid",
  gridTemplateColumns: "repeat(4, 1fr)", // 4 columns
  gridTemplateRows: "repeat(4, 1fr)", // 7 rows
  gap: "20px", // Space between buttons
  padding: "10px", // Padding around the entire grid
  borderColor: "transparent",
  // backgroundColor: "#1c1c1c", // Background color for the container
  borderRadius: "10px", // Rounded corners for the container
};

export default ThemedButtonColorPalette;
