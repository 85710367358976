import React, { createContext, useContext, useCallback, useState, useEffect } from 'react';
import { apiUpdateRemoteColor, apiFetchCurrentUserRemoteColorSetting, apiFetchCurrentUserAttributes } from "../../api/backend/user";
import { getCookie, setCookie, clearCookie } from '../../utils/cookies';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(getCookie('userId') || null);
  const [remoteColor, setRemoteColor] = useState('glass'); // Default value same as backend
  const [userAttributes, setUserAttributes] = useState(null);

  useEffect(() => {
    const fetchUserAttributes = async (userId) => {
      if (!userId) return;
      try {
        const response = await apiFetchCurrentUserAttributes(userId);
        setUserAttributes(response.data);
      } catch (error) {
        console.error('Failed to fetch user attributes:', error);
      }
    };
    const userId = getCookie('userId');
    if (userId) {
      fetchUserRemoteColor(userId);
      fetchUserAttributes(userId);
    }
  }, []);

  const setUserInfo = useCallback((userId) => {
    setCurrentUser(userId);
    setCookie('userId', userId); // Store the userId in a cookie
    fetchUserRemoteColor(userId);
    apiFetchCurrentUserAttributes (userId); // Fetch user attributes when setting user info
  }, []);

  const remoteColorUserSetting = async (userId, remoteColor) => {
    try {
      const response = await apiUpdateRemoteColor(userId, remoteColor);
      setRemoteColor(response.data.remoteColor);
    } catch (error) {
      console.error('Failed to update remote color:', error);
    }
  };

  const fetchUserRemoteColor = async (userId) => {
    if (!userId) return;
    try {
      const response = await apiFetchCurrentUserRemoteColorSetting(userId);
      setRemoteColor(response.data.remoteColor || 'glass');
    } catch (error) {
      console.error('Failed to fetch user remote color:', error);
    }
  };

  const handleLogout = useCallback(() => {
    clearCookie('userId'); // Clear the cookie on logout
    localStorage.clear();
    setCurrentUser(null);
  }, []);

  return (
    <AuthContext.Provider value={{ currentUser, userAttributes, setUserAttributes, setUserInfo, remoteColor, remoteColorUserSetting, handleLogout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
