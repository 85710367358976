import React, { useRef, useEffect } from "react";
import EmotionEmoji from "../emoji/emoji";
import "./memberSlider.scss";

function MemberSlider({
  className,
  user,
  slideBtnText,
  onHandleSlideEnd,
  style,
  slidBtnFontSize,
  slideBtnWidth,
  marginTop,
  marginRight,
  marginBottom,
  marginLeft,
  top,
}) {
  const slideRef = useRef(null);
  const slideBtnRef = useRef(null);

  let slideBounds;
  let btnBounds;

  function onSlide(ev) {
    if (!slideBtnRef.current) return; // Add null check
    ev.stopPropagation(); // Stop the event from propagating up
    const pointerX = ev.clientX;
    const pos = pointerX - btnBounds.x - btnBounds.width / 2;
    const minPos = 0;
    const maxPos =
      slideBounds.width - btnBounds.width - slideBounds.width * 0.04;

    slideBtnRef.current.style.left = clamp(minPos, pos, maxPos) + "px";

    const tolerance = 0.1;
    if (pos >= maxPos - tolerance) {
      onHandleSlideEnd(user.id); // Pass the user ID to the handler
    }
  }

  function onSlideBtnPointerDown(ev) {
    if (!slideRef.current || !slideBtnRef.current) return; // Add null check
    ev.preventDefault();
    slideBounds = slideRef.current.getBoundingClientRect();
    btnBounds = slideBtnRef.current.getBoundingClientRect();
    slideBtnRef.current.setPointerCapture(ev.pointerId);
    slideBtnRef.current.addEventListener("pointermove", onSlide);
  }

  function onSlideBtnPointerUp(ev) {
    if (!slideBtnRef.current) return; // Add null check
    ev.preventDefault();
    slideBtnRef.current.releasePointerCapture(ev.pointerId);
    slideBtnRef.current.removeEventListener("pointermove", onSlide);
    const animation = slideBtnRef.current.animate(
      { left: "0" },
      { duration: 200 }
    );
    animation.finished.then(() => {
      slideBtnRef.current.style.left = "0";
    });
  }

  function clamp(min, val, max) {
    return Math.max(min, Math.min(val, max));
  }
  // The logic for starting the slide gesture
  const onSlideStart = (ev) => {
    ev.preventDefault();
    ev.stopPropagation(); // Stop the event from propagating up
    // Add mousemove and touchmove event listeners
    document.addEventListener("mousemove", onSlide);
    document.addEventListener("touchmove", onSlide);
  };
  // The logic for ending the slide gesture
  const onSlideEnd = (ev) => {
    ev.preventDefault();
    ev.stopPropagation(); // Stop the event from propagating up
    // Remove mousemove and touchmove event listeners
    document.removeEventListener("mousemove", onSlide);
    document.removeEventListener("touchmove", onSlide);
  };
  // Attach mouse and touch event handlers to the slider button
  useEffect(() => {
    const slideButton = slideBtnRef.current;
    if (!slideButton) return; // Add null check
    const options = { passive: false }; // Options object

    slideButton.addEventListener("pointerdown", onSlideBtnPointerDown, options);
    slideButton.addEventListener("pointerup", onSlideBtnPointerUp, options);
    slideButton.addEventListener("touchstart", onSlideStart, options);
    slideButton.addEventListener("touchend", onSlideEnd, options);

    return () => {
      slideButton.removeEventListener(
        "pointerdown",
        onSlideBtnPointerDown,
        options
      );
      slideButton.removeEventListener(
        "pointerup",
        onSlideBtnPointerUp,
        options
      );
      slideButton.removeEventListener("touchstart", onSlideStart, options);
      slideButton.removeEventListener("touchend", onSlideEnd, options);
    };
  }, []);

  return (
    <div
      ref={slideRef}
      className={`slide ${className}`}
      style={{
        ...style,
        width: slideBtnWidth,
        marginTop: marginTop,
        marginRight: marginRight,
        marginBottom: marginBottom,
        marginLeft: marginLeft,
        top: top,
      }}
    >
      <div
        ref={slideBtnRef}
        className="slidebtn"
        onPointerDown={onSlideBtnPointerDown}
        onPointerUp={onSlideBtnPointerUp}
      >
        {user.thumbnailPreference === "picture" && user.profileImageUrl ? (
          <img
            src={user.profileImageUrl}
            alt={`${user.username}'s Profile`}
            className="badgepic"
          />
        ) : (
          <div className="emoji-wrapper">
            <EmotionEmoji currentEmotionId={user.emotionconId} />
          </div>
        )}
      </div>
      <div className="label" style={{ fontSize: slidBtnFontSize }}>
        {slideBtnText}
      </div>
    </div>
  );
}
export default MemberSlider;
