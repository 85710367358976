import React, { useState, useEffect } from "react";

 const BlinkingVerticalTwoBarsIcon = ({
  width = "25",
  height = "25",
  style = {},
  className = "",
}) => {
    const [isOn, setIsOn] = useState(false);

    useEffect(() => {
        // Start blinking
        const intervalId = setInterval(() => {
          setIsOn((prev) => !prev); // Toggle the light state
        }, 1000); // Change the light state every 1000ms (1 second)
        // Return a cleanup function that clears the interval when the component unmounts
        return () => {
          clearInterval(intervalId);
        };
      }, []); // Empty dependency array means this effect runs only once on mount

  const fillColor =  isOn
  ? "var(--color-redish)"
  : "var(--color-corveteRed)"; 

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 217 384"
      style={style}
      className={className}
    >
      <path
        d="M224,435.8V76.1A12.1,12.1,0,0,0,211.8,64H140.2A12.1,12.1,0,0,0,128,76.1V435.8A12.184,12.184,0,0,0,140.2,448h71.6A12.121,12.121,0,0,0,224,435.8Z"
        transform="translate(-128 -64)"
        fill={fillColor}
      />
      <path
        d="M371.8,64H300.2A12.163,12.163,0,0,0,288,76.1V435.8A12.184,12.184,0,0,0,300.2,448h71.6A12.184,12.184,0,0,0,384,435.8V76.1A12.1,12.1,0,0,0,371.8,64Z"
        transform="translate(-167 -64)"
        fill={fillColor}
      />
    </svg>
  );
};
export default BlinkingVerticalTwoBarsIcon;
