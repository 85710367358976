import React from "react";
import "./whiteGoldBtn.scss";

const WhiteGoldButtonComponent = ({
  className = "white-gold-btn",
  onClick,
  disabled = false,
  isSubmit = false,
  whiteGoldBtnImage = "",
  whiteGoldBtnText = "",
  whiteGoldBtnTextFontSize,
  whiteGoldBtnWidth,
  whiteGoldBtnHeight,
  whiteGoldBtnFontFamily,
  whiteGoldBtnBoxShadow,
  whiteGoldBtnFontWeight,
  whiteGoldBtnFontColor,
  marginLeft = "0px", 
  marginRight = "0px", 
  animationActive = false,
  extraStyles = {}, 
}, ref) => (
  <button
    ref={ref}
    className={`white-gold-btn position-relative light-gold-btn ${className}`}
    onClick={onClick}
    disabled={disabled}
    type={isSubmit ? "submit" : "button"}
    style={{
      ...extraStyles, 
      width: whiteGoldBtnWidth,
      height: whiteGoldBtnHeight,
      boxShadow: `${whiteGoldBtnBoxShadow} !important`,
      marginLeft: marginLeft,
      marginRight: marginRight,
      animation: animationActive ? "active 0.9s alternate infinite" : "none",
    }}
  >
    {whiteGoldBtnImage}
    <span
      style={{
        fontSize: whiteGoldBtnTextFontSize,
        fontFamily: whiteGoldBtnFontFamily,
        fontWeight: whiteGoldBtnFontWeight,
        color: whiteGoldBtnFontColor,
      }}
    >
      {whiteGoldBtnText}
    </span>
  </button>
);

const WhiteGoldButton = React.forwardRef(WhiteGoldButtonComponent);

export default WhiteGoldButton;
