import React from "react";
import "./goldAnimatedButton.scss";

const ImgTopBtn = ({
  className = "",
  onClick,
  disabled = false,
  isSubmit = false,
  image,
  imgTopBtnText = "",
  imgTopBtnFontColor = "#000",
  imgTopBtnBorderColor = "transparent",
  imgTopBtnTextFontSize = "",
  imgTopBtnWidth = "auto",
  imgTopBtnHeight = "auto",
  imgTopBtnFontFamily,
  imgTopBtnBoxShadow = "none",
  imgTopBtnFontWeight,
  imgTopBtnBorderRadius,
  marginLeft = "0px",
  marginRight = "0px",
  paddingTop = "0px",
  paddingBottom = "0px",
  imageRowReverse = false,
  paddingLeft = "0px",
  paddingRight = "0px",
  imageOnTop = false,
  animationActive = false, // Default to false if not provided
  imgTopBtnTextDecorationColor = "inherit",
  textClassName = "",
  extraImgTopBtnStyles = {},
  position,
  right,
  left,
}) => {
  const buttonStyle = {
    position,
    right,
    left,
    width: imgTopBtnWidth,
    height: imgTopBtnHeight,
    borderRadius: imgTopBtnBorderRadius,
    borderColor: imgTopBtnBorderColor,
    marginLeft: marginLeft,
    marginRight: marginRight,
    paddingTop: paddingTop,
    height: imgTopBtnHeight,
    paddingBottom: paddingBottom,
    paddingLeft: paddingLeft,
    paddingRight: paddingRight,
    boxShadow: imgTopBtnBoxShadow,
    animation: animationActive ? "active 0.9s alternate infinite" : "none",
    display: "flex",
    flexDirection: imageOnTop
      ? "column"
      : imageRowReverse
        ? "row"
        : "row-reverse",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: imgTopBtnFontColor, // If you want to set the background color
    borderRadius: "32px",
    backgroundColor: "linear-gradient(92deg, #faf9f5 0%, #ffe39d 80%)",
    boxShadow:
      "box-shadow: 0 4px 3px 1px #000000bb, 0 6px 8px #000000bb, 0 -4px 4px #000000bb, 0 -6px 4px #000000bb, inset 0 0 3px 0 #000000bb",
    // React automatically applies the necessary vendor prefixes for backdrop filters
    backdropFilter: "brightness(4) saturate(3) blur(4rem)",
    // You don't typically need to set both the prefixed and unprefixed versions for backdrop-filter
    // but if you do, React accepts vendor prefixes in inline styles using the following format:
    WebkitBackdropFilter: "brightness(4) saturate(3) blur(4rem)",
    ...extraImgTopBtnStyles,
  };

  const textStyle = {
    fontSize: imgTopBtnTextFontSize,
    fontFamily: imgTopBtnFontFamily,
    fontWeight: imgTopBtnFontWeight,
    color: imgTopBtnFontColor,
    textDecorationColor: imgTopBtnTextDecorationColor,
  };

  return (
    <button
      className={`globalized-btn position-relative ${className}`}
      onClick={(e) => {
        if (onClick) onClick(e);
      }}
      disabled={disabled}
      type={isSubmit ? "submit" : "button"}
      style={buttonStyle}
    >
      {imageOnTop && image && (
        <div className="image-container" style={{ marginBottom: "5px" }}>
          {image}
        </div>
      )}
      <span className={`${textClassName}`} style={textStyle}>
        {imgTopBtnText}
      </span>
      {!imageOnTop && image && (
        <div className="image-container" style={{ marginLeft: "5px" }}>
          {image}
        </div>
      )}
    </button>
  );
};

export default ImgTopBtn;
