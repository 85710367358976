import React, { Suspense } from "react";
import { Routes, Route, useLocation } from "react-router-dom"; // routes =mapping a URL path to a component, route=replaces switch cases
import Layout from "./components/layout/layout";
import NotificationDropdown from "./components/notifications/notificationDropdown ";
import "./index.scss"; // Global styles for color is also in this file
import "./styles/blinkingCursors.scss";
import "./styles/sharedGlobalStyles.scss";
// Lazy loading components
const LandingPage = React.lazy(() => import("./pages/landing/landing"));
const SkitURL = React.lazy(() => import("./pages/skitsUrl/skitsUrl"));
const Skits = React.lazy(() => import("./pages/skitsLists/skitsListPage"));
const UsersWhoVotedListingPage = React.lazy(
  () => import("./pages/usersWhoVotedListingPage/usersWhoVotedListingPage")
);
const TermsAndConditions = React.lazy(() =>
  import("./pages/rewardPointTerms/termsAndConditions")
);

const TopSkitsListsPage = React.lazy(
  () => import("./pages/topSkistLists/topSkistListsPage")
);
const UserProfilePage = React.lazy(
  () => import("./pages/profile/userProfilePage")
);
const SubscribersListPage = React.lazy(
  () => import("./pages/subscriptionsPage/subscriberListPage")
);

const SubscriptionListPage = React.lazy(
  () => import("./pages/subscriptionsPage/subscriptionListPage")
);

const InfoPage = React.lazy(() => import("./pages/information/infoPage"));

const CurrentUserSkitDashboard = React.lazy(
  () => import("./pages/skitDashboard/skitDashboard")
);
const CurrentUserAdsManagerDashboard = React.lazy(
  () => import("./pages//adsManagerDashboard/adsManagerDashboard")
);
const SearchAccountsPage = React.lazy(
  () => import("./pages/searchAccountsPage/searchAccountsPage")
);

const App = () => {
  const location = useLocation();
  return (
    <Suspense>
      {/* Wrapped the app with the NotificationDropdown component in the index.js and setting it outside the Routes component, so it renders consistently across all routes.
       */}

      {location.pathname !== "/" && <NotificationDropdown />}

      {/* Display this while waiting for the component to load */}
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<LandingPage />} />
          <Route exact path="skitslist" element={<Skits />} />
          <Route path="/skit/:skitId" element={<SkitURL />} />
          <Route
            path="/skit/:skitId/ratings"
            element={<UsersWhoVotedListingPage />}
          />
          <Route path="top-skits" element={<TopSkitsListsPage />} />
          <Route path="/user-profile/:userId" element={<UserProfilePage />} />
          <Route
            path="/user/:userId/subscribers"
            element={<SubscribersListPage />}
          />
          <Route path="/user/subscribers" element={<SubscriptionListPage />} />
          <Route path="/information" element={<InfoPage />} />
          <Route
            path="/user-profile/:userId/skit-dashboard"
            element={<CurrentUserSkitDashboard />}
          />
          <Route
            path="/user-profile/:userId/ads-dashboard"
            element={<CurrentUserAdsManagerDashboard />}
          />
          <Route path="/search-accounts" element={<SearchAccountsPage />} />
          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          />

          {/* This is your new route */}
          {/* if user is not authenticated then user is redirected to the landing page
        {/* <Route
          path="skitslist"
          element={isAuthenticated() ? <Skits /> : <Navigate to="/" replace />}
        />
        <Route
          path="/skit/:skitId"
          element={
            isAuthenticated() ? <SkitURL /> : <Navigate to="/" replace />
          }
        />
        <Route
          path="/skit/:skitId/ratings"
          element={
            isAuthenticated() ? (
              <UsersWhoVotedListingPage />
            ) : (
              <Navigate to="/" replace />
            )
          }
        /> */}
        </Route>
      </Routes>
    </Suspense>
  );
};
export default App;

// Why Use Lazy Loading?
// Performance Improvement: Particularly beneficial for
// larger applications with many components. It reduces the
// initial load time by splitting the bundle into smaller chunks
// and only loading them when needed.
// Bandwidth Conservation: Useful in bandwidth-constrained
// scenarios, as it minimizes the amount of data transferred until it is necessary.
// User Experience: Enhances user experience by speeding up the initial
// page load, which can help retain users who might leave a site that takes too long to load.
